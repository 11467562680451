@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Niconne';
  src: local('Niconne'), url(./fonts/Niconne-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoSlab';
  src: local('RobotoSlab'), url(./fonts/RobotoSlab.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'RobotoSlab', 'serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
